.nextImageWrapper {
  display: flex;

  .nextImageLayoutFill {
    position: relative !important; /* override next/image in-line styles */
    height: unset !important; /* override next/image in-line styles */
  }

  & div:first-child {
    position: relative !important;
    flex: 1;

    .nextImage {
      width: 100%;
      height: 100%;
    }
  }
}
