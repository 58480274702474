@import "../../media.css";
@import "../../mixins.css";

.component {

}

.drawerWrapper {
  /* Overlay Animations */
  &.closing {
    .overlay {
      opacity: 0;
    }
  }

  &.closed {
    .scrollable {
      top: 100%;
      left: 100%;
    }

    .overlay {
      opacity: 0;
      height: 0;
      width: 0;
      top: 100%;
      left: 100%;
    }
  }

  &.open {
    .scrollable {
      top: 0;
      left: 0;
      overflow: hidden;
    }

    .overlay {
      opacity: 0.55;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  /* Right Drawer Animations */
  &.right {
    .drawer {
      transition: right 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &.closing {
      .drawer {
          right: -480px;
          @media (max-width: 480px) {
            right: -100%;
          }
        &.cart_standard_width {
          right: -480px;
          @media (max-width: 480px) {
            right: -100%;
          }
        }
        &.cart_double_width {
          right: -850px;
          @media (max-width: 850px) {
            right: -100%;
          }
        }
      }
    }

    &.closed {
      .drawer {
          right: -480px;
          @media (max-width: 480px) {
            right: -100%;
          }
        &.cart_standard_width {
          right: -480px;
          @media (max-width: 480px) {
            right: -100%;
          }
        }
        &.cart_double_width {
          right: -850px;
          @media (max-width: 850px) {
            right: -100%;
          }
        }
      }
    }

    &.open {
      .drawer {
        right: 0px;
      }
    }
  }

  /* Left Drawer Animations */
  &.left {
    .drawer {
      transition: left 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    &.closing {
      .drawer {
          left: -480px;
          @media (max-width: 480px) {
            left: -100%;
          }
        &.cart_standard_width {
          left: -480px;
          @media (max-width: 480px) {
            left: -100%;
          }
        }
        &.cart_double_width {
          left: -850px;
          @media (max-width: 850px) {
            left: -100%;
          }
        }
      }
    }

    &.closed {
      .drawer {
          left: -480px;
          @media (max-width: 480px) {
            left: -100%;
        }
        &.cart_standard_width {
          left: -480px;
          @media (max-width: 480px) {
            left: -100%;
          }
        }
        &.cart_double_width {
          left: -850px;
          @media (max-width: 850px) {
            left: -100%;
          }
        }
      }
    }

    &.open {
      .drawer {
        left: 0px;
        @media(--smartphone) {
          width: 100%;
        }
      }
    }
  }
}

.overlay {
  @mixin background_alternate;
  position: fixed;
  opacity: 0.55;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  transition: opacity .5s ease-in-out;
}

.scrollable {
  -webkit-overflow-scrolling: touch;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  height: 100%;
  overflow-y: scroll;
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  @media (--tablet-and-desktop) {
    padding: 60px 0;
  }
}

.drawer {
  align-items: center;
  @mixin background_primary;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  position: fixed;
  top: 0px;
  bottom: 0px;
  z-index: 20;
  height: 100%;
  padding: 0;
    width: 480px;
    @media (max-width: 480px) {
      width: 100%;
    }
  &.cart_standard_width {
    width: 480px;
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  &.cart_double_width {
    width: 850px;
    overflow: hidden;
    @media (max-width: 850px) {
      width: 100%;
      overflow: auto;
    }
  }
}

.content {
  width: 100%;
  height: 100%;
}
