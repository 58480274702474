@import "../../mixins.css";

.component {
}

.desktop {
  width: 14%;
  height: 100%;
  position: sticky;
  top: 80px;
  padding-bottom: 80px;
  maring-right: 2%;
  @mixin hide_on_smartphone_and_tablet;
}

.smartPhoneAndTablet {
  flex: 1;
  @mixin hide_on_desktop;
}
